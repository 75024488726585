/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import '../App.css';

function SearchForm() {

    // eslint-disable-next-line no-unused-vars
    const [searchTerm, setSearchTerm] = React.useState("");

    const handleChange = event => {
      setSearchTerm(event.target.value);
      console.log(event.target.value)
    }

    const handleSubmit = () => {
        alert("search coming soon")
    }
  

    // called once on component load
    useEffect(() => {
        
    }, [])

    return (    
        null
    //     <form className="d-sm-inline-block form-inline navbar-search" onSubmit={handleSubmit}>
    //     <div className="input-group">
    //         <input 
    //             type="text" 
    //             className="form-control border-0 small" 
    //             placeholder="search coming soon"
    //             value={searchTerm}
    //             onChange={handleChange} 
    //             aria-label="Search" 
    //             aria-describedby="basic-addon2" />
    //         <div className="input-group-append">
    //             <button className="btn btn-primary" disabled="true" type="submit">
    //                 <i className="fas fa-search fa-sm"></i>
    //             </button>
    //         </div>
    //     </div>
    // </form>


    );
}

export default SearchForm;