import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import "../App.css";
import * as API from "../services/API";
import Header from "./Header";
import ReactTooltip from "react-tooltip";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { mainBG, eventBG } from "../styles.jsx";
import { useParams } from "react-router-dom";
import * as Util from "../services/Util";
import * as Config from "../services/Config";
import * as tokens from "../services/Contract";
import Web3 from "web3";

const Profile = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const passedRound = params.round ? params.round : 0;
  const [round, setRound] = useState(passedRound);
  const [playerCards, setPlayerCards] = useState([]);
  const [username, setUsername] = useState(null);
  const [referrals, setReferrals] = useState(null);
  const [playerResults, setPlayerResults] = useState([]);
  const [displayCards, setDisplayCards] = useState([]);
  const [selectedRoundResults, setSelectedRoundResults] = useState(null);
  const [encryptionKeys, setEncryptionKeys] = useState(0);
  const [tokenBalanceMain, setTokenBalanceMain] = useState(0);
  const [tokenBalanceVault, setTokenBalanceVault] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [eventActive, setEventActive] = useState(
    Util.isBetweenDates(Config.eventStart, Config.eventEnd, new Date())
  );

  useEffect(() => {
    fetchData();
    fetchUserData();
    displayMintopolyCards();
    fetchTokenValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  useEffect(() => {
    displayMintopolyCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerCards]);

  const redirect = () => {
    if (params.address === undefined) {
      navigate("/players");
    }
  };

  async function fetchData() {
    if (!params.round) {
      let currentRound = await API.getActiveRound();
      setRound(currentRound);
    }
  }

  async function fetchUserData() {
    if (params.address) {
      let playerData = await API.getPlayerData(params.address);
      // console.log(playerData.player.referrals);
      if (playerData.cards && playerData.results[0]) {
        let results = [];
        for (let r of playerData.results) {
          if (
            r.userTallies[0]?.forks?.number === 0 &&
            r.userTallies[0]?.netWorth <= 0.01
          )
            continue;
          else results.push(r);
        }

        setPlayerCards(playerData.cards);
        setPlayerResults(results);
        setUsername(playerData.player.username);
        setEncryptionKeys(playerData.player.tickets);
        setReferrals(playerData.player.referrals);
      }

      if (params.round && playerData.results[0]) {
        let selected = playerData.results.find((i) => i.round === params.round);
        if (selected) {
          setSelectedRoundResults(selected);
        } else {
          setSelectedRoundResults(playerData.results[0]);
        }
      } else {
        // console.log(playerData.results[0].userTallies[0].investmentsOwned);
        setSelectedRoundResults(playerData.results[0]);
      }
    }
  }

  async function fetchTokenValues() {
    // get balances of tokens on Mainnet AND Polygon
    const mainnet =
      "https://mainnet.infura.io/v3/5bcd66e7723f42a69337335fe15c11e9";
    const polygon =
      "https://nd-773-259-988.p2pify.com/91bda0e9e8bd3ad7046564bb6c63aae1";
    const web3 = new Web3(new Web3.providers.HttpProvider(mainnet));
    const polygonWeb3 = new Web3(new Web3.providers.HttpProvider(polygon));
    let mintopolyMoneyContract, polygonContract;
    try {
      mintopolyMoneyContract = new web3.eth.Contract(
        tokens.MintopolyMoneyABI,
        tokens.MintopolyMoneyAddress
      );
      polygonContract = new polygonWeb3.eth.Contract(
        tokens.PolygonABI,
        tokens.PolygonAddress
      );
      const mainnetBalance = await mintopolyMoneyContract.methods
        .balanceOf(params.address)
        .call();
      const polygonBalance = await polygonContract.methods
        .balanceOf(params.address)
        .call();
      // console.log(mainnetBalance);
      setTokenBalanceMain(mainnetBalance / Math.pow(10, 8));
      setTokenBalanceVault(polygonBalance / Math.pow(10, 8));
    } catch (e) {
      console.log(e);
    }
  }

  function displayMintopolyCards() {
    let cardDisplay = [];
    let displayNum = 8; // display 8 cards, adding palceholders as needed unless the user has more
    if (playerCards.length > 8) {
      displayNum = playerCards.length;
    }
    const numCards = playerCards.length;
    const placeholder =
      '<img class="mintopoly-card-placeholder my-1 mx-1" data-tip="Buy NFT Mintopoly Cards to receive boost!" src="https://www.mintopoly.io/assets/mintopoly-card-placeholder.png" width="120" />';
    for (let i = 0; i < playerCards.length; i++) {
      const imageLink = playerCards[i].image;
      cardDisplay.push(
        '<img class="mintopoly-card-placeholder my-1 mx-1" data-tip="' +
          playerCards[i].name +
          " <br> " +
          playerCards[i].description +
          '" src=' +
          imageLink +
          ' width="120" />'
      );
    }

    for (let i = 0; i < displayNum - numCards; i++) {
      cardDisplay.push(placeholder);
    }
    setDisplayCards(cardDisplay);
  }

  function displayReferrals() {
    let returnList = "";
    if (referrals) {
      for (let i = 0; i < referrals.length; i++) {
        returnList +=
          '<small><a href="../player/' +
          referrals[i] +
          '">' +
          referrals[i] +
          "</a></small><br/>";
      }
    }
    return returnList;
  }

  function getHighestRank() {
    if (playerResults.length === 0) {
      return "N/A";
    }
    let highest = 50000;
    for (let i = 0; i < playerResults.length; i++) {
      if (playerResults[i].userTallies[0].leaderboard.rank < highest) {
        highest = playerResults[i].userTallies[0].leaderboard.rank;
      }
    }

    // super sloppy...
    let times = 0;
    for (let i = 0; i < playerResults.length; i++) {
      if (playerResults[i].userTallies[0].leaderboard.rank === highest) {
        times += 1;
      }
    }

    return "#" + highest + " (" + times + "x)";
  }

  ChartJS.register(ArcElement, Tooltip, Legend);

  /*   const chartOptions = {
    maintainAspectRatio: true,
    plugins: {
      legend: { display: false },
      title: {
        display: false,
      },
    },
    cutout: 60,
  }; */

  // eslint-disable-next-line no-unused-vars
  const chartData = {
    labels: [
      "Mining Rigs",
      "Validator Nodes",
      "Oracles",
      "Smart Contracts",
      "Auto Trading Bots",
      "Decentralized Exchanges",
      "Centralized Exchanges",
    ],
    datasets: [
      {
        data:
          selectedRoundResults &&
          selectedRoundResults.userTallies[0].investmentsOwned
            ? [
                selectedRoundResults.userTallies[0].investmentsOwned[0],
                selectedRoundResults.userTallies[0].investmentsOwned[1],
                selectedRoundResults.userTallies[0].investmentsOwned[2],
                selectedRoundResults.userTallies[0].investmentsOwned[3],
                selectedRoundResults.userTallies[0].investmentsOwned[4],
                selectedRoundResults.userTallies[0].investmentsOwned[5],
                selectedRoundResults.userTallies[0].investmentsOwned[6],
              ]
            : null,
        backgroundColor: [
          "rgba(255, 205, 25, 0.15)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.45)",
          "rgba(255, 205, 25, 0.6)",
          "rgba(255, 205, 25, 0.72)",
          "rgba(255, 205, 25, 0.88)",
          "rgba(255, 205, 25, 1)",
        ],
        borderColor: [
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
          "rgba(255, 205, 25, 0.3)",
        ],
      },
    ],
  };

  return (
    <>
      {eventActive ? (
        <style jsx global>
          {eventBG}
        </style>
      ) : (
        <style jsx global>
          {mainBG}
        </style>
      )}

      <div>
        {redirect()}
        <Helmet>
          <title>Explorer - {username ? username : params.address}</title>
        </Helmet>

        <Header round={round} paramSet={params.round ? true : false} />

        <div className="container-fluid my-5 pt-2">
          <div className="mt-5 mb-4">
            {username ? (
              <h1 className="mt-4 mb-3 text-center font-weight-bold text-white text-break mx-3">
                {username}
              </h1>
            ) : null}
            <h4 className="mb-0 text-center font-weight-normal text-white text-break mx-3">
              {params.address}
            </h4>

            <h4 className="mt-4 mb-0 text-center font-weight-normal text-break mx-3">
              <a href={"https://moon.mintopoly.io/profile/" + params.address}>
                Moon Profile
              </a>
            </h4>
          </div>

          <div className="row my-4">
            <div className="col-12">
              <div className="shadow mb-4">
                <div className="my-3">
                  <div className="text-center">
                    <div className="row">
                      <div className="px-3 mx-auto col-lg-12 col-md-8 col-sm-12">
                        {displayCards.map((card, i) => {
                          return (
                            <span className="px-1" key={i}>
                              <span
                                dangerouslySetInnerHTML={{ __html: card }}
                              />
                              <ReactTooltip
                                effect="solid"
                                multiline={true}
                                backgroundColor="black"
                                border={true}
                                borderColor="white"
                              />
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-lg-5">
              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Highest Finish
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-white">
                        {getHighestRank()}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-trophy fa-2x text-light"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Player Since
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-white">
                        {playerResults[0]
                          ? "Round " + playerResults.slice(-1)[0].round
                          : "N/A"}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-calendar-alt fa-2x text-light"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Rounds Played
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-white">
                        {playerResults.length}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-gamepad fa-2x text-light"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Encryption Keys
                      </div>
                      <div className="h5 mb-0 font-weight-bold text-white">
                        {encryptionKeys.toLocaleString()}
                      </div>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-key fa-2x text-light"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Token Balance
                      </div>
                      <span className="h5 mb-0 font-weight-bold text-white">
                        {tokenBalanceMain > 0
                          ? "₼" + Util.currencyFormat(tokenBalanceMain)
                          : "N/A"}
                      </span>{" "}
                      <small>mainnet</small> &nbsp;
                      <span className="h5 mb-0 font-weight-bold text-white">
                        {tokenBalanceVault > 0
                          ? "₼" + Util.currencyFormat(tokenBalanceVault)
                          : "N/A"}
                      </span>{" "}
                      <small>vault</small>
                    </div>
                    <div className="col-auto">
                      <i className="fas fa-coins fa-2x text-light"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card border-left-primary shadow mb-2 py-2">
                <div className="card-body">
                  <div className="row no-gutters align-items-center">
                    <div
                      className="col mr-2"
                      style={{ overflowY: "scroll", maxHeight: "250px" }}
                    >
                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                        Referrals ({referrals ? referrals.length : 0})
                      </div>
                      <span
                        className="text-warning"
                        dangerouslySetInnerHTML={{ __html: displayReferrals() }}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-7">
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold">PREVIOUS RESULTS</h6>
                </div>
                <div className="card-body">
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: "800px" }}
                  >
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellSpacing="0"
                    >
                      <thead>
                        <tr>
                          <th>Round</th>
                          <th>Rank</th>
                          <th>Net Worth</th>
                          <th>Forks</th>
                          <th>Cards</th>
                        </tr>
                      </thead>
                      <tbody>
                        {playerResults.map((result, i) => {
                          return (
                            <tr key={i}>
                              <td>{result.round}</td>
                              <td>{result.userTallies[0].leaderboard.rank}</td>
                              <td>
                                ₼
                                {Util.currencyFormat(
                                  result.userTallies[0].netWorth
                                )}
                              </td>
                              <td>
                                {result.userTallies[0].forks.number} (
                                {Math.round(
                                  result.userTallies[0].forks.bonus * 100
                                )}
                                %)
                              </td>
                              <td>
                                {
                                  Object.keys(result.userTallies[0].activeCards)
                                    .length
                                }
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
