/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import '../App.css';
import * as API from '../services/API';
import * as util from '../services/Util';
import React, { useEffect, useState, useRef } from 'react';
import Head from 'next/head';
import { Container } from 'react-bootstrap';

const Header = (props) => {
  const menu = useRef(null);
  const [isDocumentRoot, setIsDocumentRoot] = useState(false);
  const [menuToggle, setMenuToggle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [announcementAlert, setAnnouncementAlert] = useState(false);
  const [pathName, setPathName] = useState(null);
  const [allRounds, setAllRounds] = useState([]);
  const [activeRound, setActiveRound] = useState(true);
  const [nextRoundTime, setNextRoundTime] = useState(
    '[next round not yet set]'
  );

  const closeOpenMenus = (e) => {
    if (menu.current && !menu.current.contains(e.target)) {
      setMenuToggle(false);
    }
  };

  async function fetchNextRoundTime() {
    // this could be anything - finished, active, or future
    const currentRoundData = await API.getRoundDetails(Number(allRounds[0]));
    const nextRoundData = await API.getRoundDetails(Number(allRounds[0] + 1));

    if (currentRoundData) {
      if (
        currentRoundData.totalBlocks > currentRoundData.currentBlock &&
        currentRoundData.currentBlock > 0
      ) {
        return; // current round is active, just stop here
      }

      if (nextRoundData) {
        // if this highest round is in the future, set the start time
        if (nextRoundData.currentBlock < 0) {
          setActiveRound(false);
          const secondsToStart =
            Math.abs(new Date(nextRoundData.startTime) - new Date()) / 1000;
          setNextRoundTime(util.displayTime(secondsToStart));
        }
      }
    }
  }

  async function getAllRounds() {
    const roundIDs = await API.getRoundIDs();
    roundIDs.sort(function (a, b) {
      return b - a;
    });
    setAllRounds(roundIDs);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isLoading) {
      getAllRounds();

      let path = '';
      if (props.paramSet) {
        path = window.location.href.slice(
          0,
          -props.round.toString().length - 1
        );
      } else {
        path = window.location.href;
      }
      if (path.charAt(path.length - 1) === '#') {
        // remove trailing slash
        path = path.slice(0, -1);
      }
      if (path.charAt(path.length - 1) === '/') {
        // remove trailing slash
        path = path.slice(0, -1);
      }
      setPathName(path);
      // fetchNextRoundTime();

      if (path === 'https://explorer.mintopoly.io') setIsDocumentRoot(true);
      else setIsDocumentRoot(false);

      document.addEventListener('mousedown', closeOpenMenus);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <Head>
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />
        <link
          href='https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap'
          rel='stylesheet'
        />
      </Head>

      {menuToggle ? (
        <>
          <Container className='pb-5'>
            <div
              className='globalNavBar'
              style={{ background: "url('/img/moonBG-banner.jpg') !important" }}
            >
              <div
                style={{
                  width: '125px',
                  position: 'absolute',
                  top: '7px',
                  left: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                className='globalNavIcons'
              >
                <a className='' href='https://mintopoly.io/dashboard'>
                  <img
                    className='breathing-effect'
                    style={{
                      animation: 'breathing 7.5s ease-out infinite normal',
                    }}
                    width='60px'
                    src='/img/simulator-icon.png'
                    alt='sim-icon'
                  />
                </a>

                <a className='' href='https://moon.mintopoly.io'>
                  <img
                    className='breathing-effect'
                    style={{
                      animation: 'breathing 8.5s ease-out infinite normal',
                    }}
                    width='60px'
                    src='/img/moon-icon.png'
                    alt='moon-icon'
                  />
                </a>
              </div>
              {!isDocumentRoot ? (
                <div className='rootIcon'>
                  <a
                    className='mx-auto dashboard-button'
                    href={'/' + props.round}
                  >
                    <img
                      className='mt-4'
                      src='/img/explorer-icon.png'
                      width='100%'
                      alt='explorer-icon'
                    />
                  </a>
                </div>
              ) : null}

              {announcementAlert ? (
                <a
                  onClick={() => setMenuToggle(!menuToggle)}
                  className='globalNavIcons'
                >
                  <img
                    className='breathing-effect'
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '10px',
                      color: '#c6b1ff',
                      animation: 'breathing 8.5s ease-out infinite normal',
                    }}
                    width='60px'
                    src='/img/menu-icon-alert.png'
                    alt='menu-icon-alert'
                  />
                </a>
              ) : (
                <a
                  onClick={() => setMenuToggle(!menuToggle)}
                  className='globalNavIcons'
                >
                  <img
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '10px',
                      color: '#c6b1ff',
                    }}
                    width='60px'
                    src='/img/menu-icon.png'
                    alt='menu-icon'
                  />
                </a>
              )}
            </div>
          </Container>

          <Container className='menuOverlay'>
            <div className='menuGrid globalNavIcons' ref={menu}>
              <a href='https://mintopoly.io/'>
                <img src='/img/home-icon.png' width='100px' alt='home-icon' />
              </a>

              <a
                href='https://www.nitrodome.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/img/nitro-base-lg.png'
                  width='100px'
                  alt='nitrodome-icon'
                />
              </a>

              <a
                href='https://discord.gg/piprotocol'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src='/img/discord-icon.png'
                  width='100px'
                  alt='discord-icon'
                />
              </a>

              <a href='https://mintopoly.io/account'>
                <img
                  src='/img/account-icon.png'
                  width='100px'
                  alt='account-icon'
                />
              </a>

              <a href='https://guide.mintopoly.io'>
                <img src='/img/guide-icon.png' width='100px' alt='guide-icon' />
              </a>

              <a href='/announcements'>
                {announcementAlert ? (
                  <img
                    src='/img/news-icon-alert.png'
                    width='100px'
                    alt='news-icon-alert'
                  />
                ) : (
                  <img src='/img/news-icon.png' width='100px' alt='news-icon' />
                )}
              </a>
            </div>
          </Container>
        </>
      ) : (
        <Container className='pb-5'>
          <div
            className='globalNavBar'
            style={{ background: "url('/img/moonBG-banner.jpg') !important" }}
          >
            <div
              style={{
                width: '125px',
                position: 'absolute',
                top: '7px',
                left: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              className='globalNavIcons'
            >
              <a href='https://mintopoly.io/dashboard'>
                <img
                  className='breathing-effect'
                  style={{
                    animation: 'breathing 7.5s ease-out infinite normal',
                  }}
                  width='60px'
                  src='/img/simulator-icon.png'
                  alt='sim-icon'
                />
              </a>

              <a href='https://moon.mintopoly.io'>
                <img
                  className='breathing-effect'
                  style={{
                    animation: 'breathing 8.5s ease-out infinite normal',
                  }}
                  width='60px'
                  src='/img/moon-icon.png'
                  alt='moon-icon'
                />
              </a>
            </div>

            {!isDocumentRoot ? (
              <div className='rootIcon'>
                <a className='mx-auto' href={'/' + props.round}>
                  <img
                    className='mt-4'
                    src='/img/explorer-icon.png'
                    width='100%'
                    alt='explorer-icon'
                  />
                </a>
              </div>
            ) : null}

            {announcementAlert ? (
              <a
                onClick={() => setMenuToggle(!menuToggle)}
                className='globalNavIcons'
              >
                <img
                  className='breathing-effect'
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '10px',
                    color: '#c6b1ff',
                    animation: 'breathing 8.5s ease-out infinite normal',
                  }}
                  width='60px'
                  src='/img/menu-icon-alert.png'
                  alt='menu-icon-alert'
                />
              </a>
            ) : (
              <a
                onClick={() => setMenuToggle(!menuToggle)}
                className='globalNavIcons'
              >
                <img
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '10px',
                    color: '#c6b1ff',
                  }}
                  width='60px'
                  src='/img/menu-icon.png'
                  alt='menu-icon'
                />
              </a>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default Header;
