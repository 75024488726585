/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import '../App.css';
import * as util from '../services/Util';
import { BsGem } from 'react-icons/bs';

function RoundCards(props) {
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [totalBlocks, setTotalBlocks] = useState(0);
  const [currentBlock, setCurrentBlock] = useState(0);
  const [gameProgress, setGameProgress] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [contributionGoal, setContributionGoal] = useState(0);
  const [gameData, setGameData] = useState(props.gameData);

  // called once on component load
  useEffect(() => {
    setGameData(props.gameData);
    calculateData();
  }, [props]);

  async function calculateData() {
    if (gameData) {
      setTotalPlayers(gameData.totalPlayers);
      setTotalBlocks(gameData.currentBlock < 36000 ? 36000 : gameData.totalBlocks);
      setCurrentBlock(gameData.currentBlock);
      setGameProgress((gameData.currentBlock / totalBlocks) * 100);
      const secondsRemaining = (totalBlocks - gameData.currentBlock) * gameData.blockLength;
      setTimeRemaining(util.displayTime(secondsRemaining));
      if (secondsRemaining < 0) {
        setTimeRemaining(util.displayTime(Math.abs(secondsRemaining)));
      }
      if (gameData.contributionGoal) {
        setContributionGoal(gameData.contributionGoal);
      }
    }
  }

  function sumAllCash() {
    // each item in props.tallies has a cashOnHand field, return the sum of all
    if (!props.tallies) return 0;

    let sum = 0;
    for (let i = 0; i < props.tallies.length; i++) {
      sum += props.tallies[i].cashOnHand || 0;
      sum += props.tallies[i].lastTally.stakedValue || 0;
    }

    return util.currencyFormat(sum);
  }

  return (
    <>
      <div className='row'>
        <div className='col-xl-3 col-md-6 mb-4'>
          <div className='card border-left-warning shadow h-100 py-2'>
            <div className='card-body'>
              <div className='row no-gutters align-items-center'>
                <div className='col mr-2'>
                  <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>Current Block</div>
                  <div className='row no-gutters align-items-center'>
                    <div className='col-auto'>
                      <div className='h5 mb-0 mr-3 font-weight-bold text-warning'>
                        {gameData && totalBlocks < gameData.currentBlock
                          ? totalBlocks
                          : Number(currentBlock).toLocaleString()}
                      </div>
                    </div>
                    <div className='col'>
                      <div className='progress progress-sm mr-2'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{ width: gameProgress + '%' }}
                          aria-valuenow='85'
                          aria-valuemin='0'
                          aria-valuemax='100'
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-auto'>
                  <i className='fas fa-cube fa-2x text-gray-300'></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {contributionGoal !== 0 ? (
          <>
            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>Contribution Goal</div>
                      <div className='h5 mb-0 font-weight-bold text-warning'>
                        <BsGem /> {util.currencyFormat(contributionGoal)}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-bullseye fa-2x text-gray-300'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>Goal Progress</div>
                      <div className='row no-gutters align-items-center'>
                        <div className='col-auto'>
                          <div className='h5 mb-0 mr-3 font-weight-bold text-warning'>
                            <BsGem /> {gameData && util.currencyFormat(gameData.currentContributions)}
                          </div>
                        </div>
                        <div className='col'>
                          <div className='progress progress-sm mr-2'>
                            <div
                              className='progress-bar bg-primary'
                              role='progressbar'
                              style={{
                                width:
                                  gameData && (gameData.currentContributions / gameData.contributionGoal) * 100 + '%',
                              }}
                              aria-valuenow='85'
                              aria-valuemin='0'
                              aria-valuemax='100'
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>All Cash + Staking</div>
                      <div className='h5 mb-0 font-weight-bold text-warning'>
                        <BsGem /> {sumAllCash()}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-coins fa-2x text-gray-300'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>Total Players</div>
                      <div className='h5 mb-0 font-weight-bold text-warning'>
                        {Number(totalPlayers).toLocaleString()}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-user-friends fa-2x text-gray-300'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>Total Blocks</div>
                      <div className='h5 mb-0 font-weight-bold text-warning'>
                        {Number(totalBlocks).toLocaleString()}
                      </div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-cubes fa-2x text-gray-300'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-xl-3 col-md-6 mb-4'>
              <div className='card border-left-warning shadow h-100 py-2'>
                <div className='card-body'>
                  <div className='row no-gutters align-items-center'>
                    <div className='col mr-2'>
                      <div className='text-xs font-weight-bold text-light text-uppercase mb-1'>
                        {gameData && totalBlocks < gameData.currentBlock ? 'Time Since End' : 'Time Remaining'}
                      </div>
                      <div className='h5 mb-0 font-weight-bold text-warning'>{timeRemaining}</div>
                    </div>
                    <div className='col-auto'>
                      <i className='fas fa-clock fa-2x text-gray-300'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className='row'>
        <div className='col-lg-12 mb-2'>
          <div className='card shadow mb-4'>
            <div className='card-header py-3'>
              <h6 className='m-0 font-weight-bold'>ROUND PRIZES</h6>
            </div>
            <div className='card-body'>
              <ul className='fa-ul mb-0 pb-0'>
                {gameData ? (
                  gameData.rewards.map((reward, i) => {
                    return (
                      <li key={i}>
                        <span className='fa-li'>
                          <i className='fas fa-gift'></i>
                        </span>
                        {reward}
                      </li>
                    );
                  })
                ) : (
                  <small>round prizes not found</small>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RoundCards;
