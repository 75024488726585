export function isBetweenDates(start, end, check) {
  if (check >= start && check <= end) {
    // between dates
    return true;
  } else {
    // outside of dates
    return false;
  }
}

export function currencyFormat(_number) {
  if (_number === undefined) {
    return 0;
  }
  // first rounding DOWN to prevent issue where it rounds up and you don't have that much
  if (_number < 0.0000000045) {
    return 0;
  } else if (_number >= 1000000000000000000000) {
    return numFormat(Number(_number / 1000000000000000000000).toFixed(1)) + ' sextillion';
  } else if (_number >= 1000000000000000000) {
    return numFormat(Number(_number / 1000000000000000000).toFixed(1)) + ' quintillion';
  } else if (_number >= 1000000000000000) {
    return numFormat(Number(_number / 1000000000000000).toFixed(2)) + ' quadrillion';
  } else if (_number >= 1000000000000) {
    return numFormat(Number(_number / 1000000000000).toFixed(2)) + ' trillion';
  } else if (_number >= 1000000000) {
    return numFormat(Number(_number / 1000000000).toFixed(2)) + ' billion';
    // }
    // else if (_number >= 1000000) {
    //   return numFormat(Number(_number / 1000000).toFixed(3)) + " million";
  } else if (_number <= 1000) {
    return Number(_number).toFixed(8);
  } else {
    return Number(_number.toFixed(2)).toLocaleString('en-us'); // more than 1 thousand but less than 1 million
  }
}

function numFormat(n) {
  var str = n.toString().split('.');
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return str.join('.');
}

export function displayTime(_seconds) {
  var seconds = parseInt(_seconds, 10);

  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  var mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  return days + 'd ' + hrs + 'h ' + mnts + 'm';
}

export function isMintopolist(_address) {
  const mintopolists = [
    '0xc9f9b9095da3009c7594e6387a08b000709ce433',
    '0x95d92C01ac2E45c33Bd5C40Ef555C01dBde43C83',
    '0x83694b5ce034CD7F664b28e1c69FD9D2b35BD1e0',
    '0x25b335f53e3E09D854a125C36FB9bC722C743ff0',
    '0xAcca8873872C1483514bd77ab17Cc8Ea825a751f',
    '0x36595db21f4Fcd3742e5771358a543b2bEEd869b',
    '0xbA4A602Bf1b9c92b9C141b422aeE0B759750F9be',
    '0x89830F1ebffAb4899D7712a8ab6fDE1F51f20f18',
    '0x0dd761060aDba486CAa5CcE2B57Ba8445986851d',
    '0xC597021b1C15bB6da1c8aA5Ce202edf2104a24D6',
    '0x1847dc050A3680770E63C4284f3be75453eDAbeB',
    '0xe58959b62861E82eD882A249AA90Cee5c55f9788',
    '0x0C633753fA7a8E5143176090AaAf0F35b12cC60c',
    '0x0435F1194944E7eEE29aceDb7bE175a9223bE9a7',
    '0xE6943A33A364B1AA7997f636574b127a67E7C110',
    '0xE80C275F2c71A36426eAa6e25a83c0280B5B91Ca',
    '0x98eFf980C57c9D333340b3856481bF7B8698987c',
    '0x3BD7B7FB8056cC1BCE16D6D008936BD7af0CbE25',
    '0xbD44793C476F7A2061229a54bc341efC554d8D03',
    '0x049CE1c655F9e7B767EEf1169c05be6714c500eF',
    '0x0F10fBb14316C1F5f38c1dC6bf70591afec4bc6c',
    '0x3b4E8Aab17b8C3C54e1eD02daB023D89BcEef55E',
    '0xd3cE0bf8f2955e788F5ed5A4d3Bc8F54C1F5525c',
    '0x4A1660202Da91bF7324cCe19f40dC717Ef259CF7',
    '0x9A0b1B23Adc822CBA058E7825D2D00E707cc756F',
    '0x74c1a59D9eeA9cbcD144b08d04C797403623e277',
    '0x4F1686b577b58d36fD464B8497460aa920b6FE09',
    '0x7E03d22777Bfb569A62E1A04Da24546D925f44D1',
    '0xbaA79A37342562784d5758fdfA3202BbD1Af1189',
    '0x6E98797a3b0a6B02819a46975F3aaC8d8b19604E',
    '0x5dCB46Fcfe4866A479f83dF28Ab0E16075aa746d',
    '0xA2FdC2DDF5e652af96Dae3C9D16e34c9824e99AD',
    '0x7fE5e61A7eb1FF76bbE7Fc6c66598C3eF6E02B2B',
    '0xBc92c9996C0C61c8DD4F4D7F408B0b3E534c9462',
    '0xC21a016D1c3cE50D6127B7CdB1d8fe41DA9fC366',
    '0x28014ACFea5BAD80f15F771334E08D8941C5c30a',
    '0xA0C59Fe11305a4F62692a41198099C01dC271be3',
    '0xDA0b521d1ecea9425ce1e079739e8Ef3aa45c4C4',
    '0x36cb7898012E3B19070554BE377f7C0E708be229',
    '0x341f00c1b2B236EF26527590EC62F5960A356052',
    '0x079dB324676CD5100BC944586Ed9A40146a39dA8',
    '0xfC11DDcDeD074aCb0756E6f79904C2798112F011',
    '0xc21352580E49d03C568C9Ce95298Acb46004829A',
    '0x0Cf730da78C4Ed4a3CD31C7A38867e15C3a5A07A',
    '0x5f6786562bd34b86670aD96b0c2a7436De4e9128',
    '0x7CF5c60d31E7b6e8C924f37071B54112e3Ac8488',
    '0xC0EBC949d3047812b68372ef7d3c1A927f3A8De4',
    '0x510072A38be5C2e73286c1a2Ad48a72B7B3C908a',
    '0xdEf2B1cf1d473289e78b3A24F90b2560e99Fa0a5',
    '0xAC5fD2634220078910eB868566c6E0ff3B56fe8F',
    '0x5ddF903808f8337B696e2373D28c7D0df46d02EF',
    '0xA83B07BA91db714Fe789A29bD79723cC6882dc3A',
    '0x7309172EbD55bc672529B2FbF219E528eC143d6b',
  ];

  if (mintopolists.includes(_address)) {
    return true;
  } else {
    return false;
  }
}

export function isMoontopolist(_address) {
  const moontopolists = [
    '0x89830F1ebffAb4899D7712a8ab6fDE1F51f20f18',
    '0x079dB324676CD5100BC944586Ed9A40146a39dA8',
    '0x36595db21f4Fcd3742e5771358a543b2bEEd869b',
    '0x95d92C01ac2E45c33Bd5C40Ef555C01dBde43C83',
    '0xA83B07BA91db714Fe789A29bD79723cC6882dc3A',
    '0xe58959b62861E82eD882A249AA90Cee5c55f9788',
    '0xECb90b816b4D7dabC1582ED47c3fDBef0c0d280D',
    '0x66E15e444A0FA24D72ad78b54763562F242cbf75',
    '0x36cb7898012E3B19070554BE377f7C0E708be229',
    '0x7309172EbD55bc672529B2FbF219E528eC143d6b',
    '0xC0EBC949d3047812b68372ef7d3c1A927f3A8De4',
    '0xfC11DDcDeD074aCb0756E6f79904C2798112F011',
    '0xbA4A602Bf1b9c92b9C141b422aeE0B759750F9be',
    '0x2e1b9a3c0b294A05CeA65BD126414728Bdc68143',
    '0x510072A38be5C2e73286c1a2Ad48a72B7B3C908a',
    '0x7fE5e61A7eb1FF76bbE7Fc6c66598C3eF6E02B2B',
    '0x0Cf730da78C4Ed4a3CD31C7A38867e15C3a5A07A',
    '0x22386b4Ddb9f0836d35dE8Ad0D263f22d054d9A6',
    '0xCa7Cd9BEc8243531186993Eef33f781601C5b0cF',
    '0xB2AD4Cc109eFCaD0C9469C69Db830ffA426a2ac1',
    '0x5233724aa5783d49eD84dA2162b2AfdF10EF3a49',
    '0xC7EB2ac4D418184B7aE3E86D539189ABAe01336b',
    '0xA0C59Fe11305a4F62692a41198099C01dC271be3',
    '0xD5afc23604FE43F1164D0866e5963D8058B9A49D',
    '0xd3cE0bf8f2955e788F5ed5A4d3Bc8F54C1F5525c',
    '0x7b5fD4d64C54184A3BC9c1c004E83C56F06f863A',
    '0x0826A84C968Ad107aa7cCDe53571beB5c8fc789d',
    '0x4F1686b577b58d36fD464B8497460aa920b6FE09',
    '0xC597021b1C15bB6da1c8aA5Ce202edf2104a24D6',
    '0xBc92c9996C0C61c8DD4F4D7F408B0b3E534c9462',
    '0x5ddF903808f8337B696e2373D28c7D0df46d02EF',
  ];

  if (moontopolists.includes(_address)) {
    return true;
  } else {
    return false;
  }
}
