import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import "../App.css";
import * as API from "../services/API";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { mainBG } from "../styles.jsx";

function PlayerSearch() {
  const params = useParams();
  const passedRound = params.round ? params.round : 0;
  const [round, setRound] = useState(passedRound);

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
    console.log(event.target.value);
  };

  const handleSubmit = () => {
    alert("search coming soon");
  };

  async function fetchData() {
    if (!params.round) {
      let currentRound = await API.getActiveRound();
      setRound(currentRound);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  return (
    <div>
      <Helmet>
        <title>Mintopoly Block Explorer</title>
      </Helmet>

      <style jsx global>
        {mainBG}
      </style>

      <Header round={round} paramSet={params.round ? true : false} />

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-8 offset-lg-2 mt-5 text-center mb-4">
            <h1 className="mb-4 font-weight-bold text-gray-800">
              Player Lookup
            </h1>

            <form className="text-center" onSubmit={handleSubmit}>
              <input
                type="text"
                className="mb-2 text-center form-control"
                placeholder="0xc9f9b9... Eraguth..."
                value={searchTerm}
                onChange={handleChange}
              />
              <button type="submit" className="btn btn-primary btn-lg w-100">
                Search
              </button>
            </form>

            <br />
            {/* <span className="text-center font-weight-bold text-gray-800">Current Top Players:</span>
                        <span className="text-center font-weight-normal text-gray-800"><a href="#">Eraguth [0x918ec0]</a> ... <a href="#">Eraguth [0x918ec0]</a> ...
                        <a href="#">Eraguth [0x918ec0]</a> ...<a href="#">Eraguth [0x918ec0]</a> ...<a href="#">Eraguth [0x918ec0]</a> ...<a href="#">Eraguth [0x918ec0]</a> ...
                        </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerSearch;
