export const mainBG = `body {
  background-color: #000 !important;
  background: url('/img/star-bg.png');
  color: #FFFFFF;
  background-repeat: repeat-y;
  background-size: 100%;
  height: 100vh;
}
@media screen and (max-width: 1024px){
  body{
    background-size: unset;
  }
}`;

export const eventBG = `body {
  background-color: #000 !important;
  background: url('/img/event-bg.jpg');
  color: #FFFFFF;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
}
@media screen and (max-width: 1024px){
  body{
    background-size: unset;
  }
}`;
