import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Leaderboards from "./components/Leaderboards";
import Profile from "./components/Profile";
import PlayerSearch from "./components/PlayerSearch";

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Routes>
            {/* <Route exact path="/" element={<Home />}>
            <Route exact path=":round" element={<Home />} />
            <Route exact path="" element={<Home />} />
          </Route> */}

            <Route exact path="/" element={<Leaderboards />}>
              <Route exact path=":round" element={<Leaderboards />} />
              <Route exact path="" element={<Leaderboards />} />
            </Route>

            <Route exact path="/leaderboard/" element={<Leaderboards />}>
              <Route exact path=":round" element={<Leaderboards />} />
              <Route exact path="" element={<Leaderboards />} />
            </Route>

            <Route exact path="/players/" element={<PlayerSearch />} />

            <Route exact path="/player/" element={<Profile />}>
              <Route exact path=":address" element={<Profile />} />
              <Route exact path=":address/:round" element={<Profile />} />
              <Route exact path="" element={<Profile />} />
            </Route>
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
