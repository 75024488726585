
/* MINTOPOLY MONEY ON ETH MAINNET */
const MintopolyMoneyAddress = "0xF6a09deadF5A10aA7822d95e3228b2315De8f6fa";

const MintopolyMoneyABI = [
    {
    "inputs":[
        
    ],
    "stateMutability":"nonpayable",
    "type":"constructor"
    },
    {
    "anonymous":false,
    "inputs":[
        {
            "indexed":true,
            "internalType":"address",
            "name":"owner",
            "type":"address"
        },
        {
            "indexed":true,
            "internalType":"address",
            "name":"spender",
            "type":"address"
        },
        {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
        }
    ],
    "name":"Approval",
    "type":"event"
    },
    {
    "anonymous":false,
    "inputs":[
        {
            "indexed":true,
            "internalType":"address",
            "name":"from",
            "type":"address"
        },
        {
            "indexed":true,
            "internalType":"address",
            "name":"to",
            "type":"address"
        },
        {
            "indexed":false,
            "internalType":"uint256",
            "name":"value",
            "type":"uint256"
        }
    ],
    "name":"Transfer",
    "type":"event"
    },
    {
    "inputs":[
        {
            "internalType":"address[]",
            "name":"_recipients",
            "type":"address[]"
        },
        {
            "internalType":"uint256[]",
            "name":"_values",
            "type":"uint256[]"
        }
    ],
    "name":"airdrop",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"owner",
            "type":"address"
        },
        {
            "internalType":"address",
            "name":"spender",
            "type":"address"
        }
    ],
    "name":"allowance",
    "outputs":[
        {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"spender",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
        }
    ],
    "name":"approve",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"account",
            "type":"address"
        }
    ],
    "name":"balanceOf",
    "outputs":[
        {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
        }
    ],
    "name":"burn",
    "outputs":[
        
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"account",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
        }
    ],
    "name":"burnFrom",
    "outputs":[
        
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        
    ],
    "name":"cap",
    "outputs":[
        {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        
    ],
    "name":"decimals",
    "outputs":[
        {
            "internalType":"uint8",
            "name":"",
            "type":"uint8"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"spender",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"subtractedValue",
            "type":"uint256"
        }
    ],
    "name":"decreaseAllowance",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"spender",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"addedValue",
            "type":"uint256"
        }
    ],
    "name":"increaseAllowance",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        
    ],
    "name":"name",
    "outputs":[
        {
            "internalType":"string",
            "name":"",
            "type":"string"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        
    ],
    "name":"symbol",
    "outputs":[
        {
            "internalType":"string",
            "name":"",
            "type":"string"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        
    ],
    "name":"totalSupply",
    "outputs":[
        {
            "internalType":"uint256",
            "name":"",
            "type":"uint256"
        }
    ],
    "stateMutability":"view",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
        }
    ],
    "name":"transfer",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    },
    {
    "inputs":[
        {
            "internalType":"address",
            "name":"sender",
            "type":"address"
        },
        {
            "internalType":"address",
            "name":"recipient",
            "type":"address"
        },
        {
            "internalType":"uint256",
            "name":"amount",
            "type":"uint256"
        }
    ],
    "name":"transferFrom",
    "outputs":[
        {
            "internalType":"bool",
            "name":"",
            "type":"bool"
        }
    ],
    "stateMutability":"nonpayable",
    "type":"function"
    }
];


/* CHILD ERC20 ON POLYGON */
const PolygonAddress = "0xF6a09deadF5A10aA7822d95e3228b2315De8f6fa";

const PolygonABI = [
    {
      "inputs": [
        {
          "internalType": "string",
          "name": "name_",
          "type": "string"
        },
        {
          "internalType": "string",
          "name": "symbol_",
          "type": "string"
        },
        {
          "internalType": "uint8",
          "name": "decimals_",
          "type": "uint8"
        },
        {
          "internalType": "address",
          "name": "childChainManager",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Approval",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "address",
          "name": "userAddress",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "address payable",
          "name": "relayerAddress",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "bytes",
          "name": "functionSignature",
          "type": "bytes"
        }
      ],
      "name": "MetaTransactionExecuted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "previousAdminRole",
          "type": "bytes32"
        },
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "newAdminRole",
          "type": "bytes32"
        }
      ],
      "name": "RoleAdminChanged",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "sender",
          "type": "address"
        }
      ],
      "name": "RoleGranted",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "account",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "sender",
          "type": "address"
        }
      ],
      "name": "RoleRevoked",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "from",
          "type": "address"
        },
        {
          "indexed": true,
          "internalType": "address",
          "name": "to",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "value",
          "type": "uint256"
        }
      ],
      "name": "Transfer",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "DEFAULT_ADMIN_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "DEPOSITOR_ROLE",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "ERC712_VERSION",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        }
      ],
      "name": "allowance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "approve",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "decimals",
      "outputs": [
        {
          "internalType": "uint8",
          "name": "",
          "type": "uint8"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "subtractedValue",
          "type": "uint256"
        }
      ],
      "name": "decreaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "internalType": "bytes",
          "name": "depositData",
          "type": "bytes"
        }
      ],
      "name": "deposit",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "userAddress",
          "type": "address"
        },
        {
          "internalType": "bytes",
          "name": "functionSignature",
          "type": "bytes"
        },
        {
          "internalType": "bytes32",
          "name": "sigR",
          "type": "bytes32"
        },
        {
          "internalType": "bytes32",
          "name": "sigS",
          "type": "bytes32"
        },
        {
          "internalType": "uint8",
          "name": "sigV",
          "type": "uint8"
        }
      ],
      "name": "executeMetaTransaction",
      "outputs": [
        {
          "internalType": "bytes",
          "name": "",
          "type": "bytes"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getChainId",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "pure",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getDomainSeperator",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "getNonce",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "nonce",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        }
      ],
      "name": "getRoleAdmin",
      "outputs": [
        {
          "internalType": "bytes32",
          "name": "",
          "type": "bytes32"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "getRoleMember",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        }
      ],
      "name": "getRoleMemberCount",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "grantRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "hasRole",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "spender",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "addedValue",
          "type": "uint256"
        }
      ],
      "name": "increaseAllowance",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "name",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "renounceRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "bytes32",
          "name": "role",
          "type": "bytes32"
        },
        {
          "internalType": "address",
          "name": "account",
          "type": "address"
        }
      ],
      "name": "revokeRole",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "symbol",
      "outputs": [
        {
          "internalType": "string",
          "name": "",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transfer",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "sender",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "recipient",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "transferFrom",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "withdraw",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }
  ]


module.exports = {MintopolyMoneyAddress, MintopolyMoneyABI, PolygonAddress, PolygonABI}