import moment from "moment";
import Axios from "axios";
import {apiURL} from "./Config";

// Is there a current round? Return the round or -1
export async function getActiveRound() {
  const getRound = await Axios.get(apiURL + "/game/round/active");
  if (getRound.data.result > 0) {
    return getRound.data.result;
  } else {
    const roundIDs = await getRoundIDs();
    return Math.max(...roundIDs);
  }
}

// get details for a round
export async function getRoundDetails(_round) {
  const gameData = await Axios.get(apiURL + "/game/round/" + _round);

  const startTime = moment(gameData.data["startTime"]);
  const now = moment(Date.now());
  const timeDiff = now.diff(startTime, "seconds");
  const blocks = timeDiff / gameData.data["blockLength"];
  const remainder = (blocks * 100) % 100; // fractional value of next block to inject into progress bar

  return {
    startTime: gameData.data["startTime"],
    currentBlock: Math.floor(blocks),
    totalBlocks: gameData.data["totalBlocks"],
    blockLength: gameData.data["blockLength"],
    totalPlayers: gameData.data["totalPlayers"],
    stakingRate: gameData.data["stakingRate"],
    blockProgressBar: remainder,
    contributionGoal: gameData.data["contributionGoal"],
    currentContributions: gameData.data["currentContributions"],
    rewards: gameData.data["rewards"],
  };
}

// returns int of the highest round #
export async function getRoundIDs() {
  const allRounds = await Axios.get(apiURL + "/game/all");
  let roundIDs = [];
  for (let i = 0; i < allRounds.data.length; i++) {
    if (allRounds.data[i].totalPlayers > 0) {
      // this round has users, so active or past, not future!
      roundIDs.push(allRounds.data[i].round);
    }
  }
  return roundIDs;
}

export async function getLeaderboard(_round, _sliceStart, _sliceEnd) {
  let round = _round;
  // if the user called 'current' it will find current, otherwise get #
  const tallies = await Axios.get(
    apiURL +
      "/game/leaderboard?round=" +
      round +
      "&sliceStart=" +
      _sliceStart +
      "&sliceEnd=" +
      _sliceEnd
  );
  if (tallies.data === "fail") {
    console.log("error getting leaderboard");
    return;
  } else {
    return tallies.data;
  }
}

export async function getPlayerRank(_address, _round) {
  let round = _round;
  // if the caller passes 'current' for _round, it will find current round otherwise get #
  if (_round === "current") {
    const getRound = await Axios.get(apiURL + "/game/round/active");
    const activeRound = getRound.data.result;
    if (activeRound === false) {
      return null;
    }
    round = activeRound;
  }

  const rank = await Axios.get(apiURL + "/getPlayerRank", {
    address: _address,
    round: round,
  });
  if (rank.data === "fail") {
    console.log("error getting leaderboard");
    return;
  } else {
    return rank.data;
  }
}

export async function getPlayerData(_address) {
  // getting the profile of another player, not their own (i.e. don't send email or referrals)
  const player = await Axios.get(apiURL + "/player/" + _address);
  const results = await Axios.get(
    apiURL + "/player/" + _address + "/previousResults"
  );
  const cards = await Axios.get(
    apiURL + "/player/" + _address + "/ownedCardsMetadata"
  );
  return {player: player.data, results: results.data, cards: cards.data};
}
