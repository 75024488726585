import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import '../App.css';
import Header from './Header';
import * as API from '../services/API';
import * as Util from '../services/Util';
import * as Config from '../services/Config';
import { useParams } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import { mainBG, eventBG } from '../styles.jsx';
import TableCell from '@mui/material/TableCell';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Bar } from 'react-chartjs-2';
import SearchForm from './searchForm';
import RoundCards from './RoundCards';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function formatNumber(num) {
  if (num) {
    if (num <= 10) {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 8,
      });
    } else {
      return num.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  } else {
    return 0;
  }
}

function createDisplayLink(displayName, address) {
  const isMintopolist = Util.isMintopolist(address);
  let icons = '';

  if (isMintopolist) {
    icons += '<img src="../img/mintopolist-icon.png" width="16" /> &nbsp;';
  }

  const isMoontopolist = Util.isMoontopolist(address);

  if (isMoontopolist) {
    icons += '🌖 &nbsp;';
  }

  if (icons === '') {
    icons = '';
  }

  const displayLink = (
    <div
      className='content'
      dangerouslySetInnerHTML={{
        __html: icons + displayName,
      }}
    ></div>
  );

  return displayLink;
}

function Leaderboards() {
  const params = useParams();
  const passedRound = params.round ? params.round : 0;
  const [round, setRound] = useState(passedRound);
  const [userTallies, setUserTallies] = useState(null);
  const [tallyData, setTallyData] = useState([]);
  const [gameData, setGameData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [pathName, setPathName] = useState(null);
  const [allRounds, setAllRounds] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [leaderboardInfo, setLeaderboardInfo] = useState('leaderboard not found');
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [leaderboardState, setLeaderboardState] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [eventActive, setEventActive] = useState(Util.isBetweenDates(Config.eventStart, Config.eventEnd, new Date()));

  useEffect(() => {
    async function fetchData() {
      if (!params.round) {
        let currentRound = await API.getActiveRound();
        setRound(currentRound);
      }
    }

    async function fetchRoundData() {
      if (round) {
        const gameData = await API.getRoundDetails(round);
        setGameData(gameData);
        setLeaderboardInfo(
          'round #' + round + ' [block ' + gameData.currentBlock + ' of ' + gameData.totalBlocks + ']',
        );
      }
    }

    async function getAllRounds() {
      const roundIDs = await API.getRoundIDs();
      roundIDs.sort(function (a, b) {
        return b - a;
      });
      setAllRounds(roundIDs);
    }

    async function fetchLeaderboard() {
      if (round) {
        const tallies = await API.getLeaderboard(round, 0, 3500, null);
        let actual = [];

        for (let t of tallies) {
          if (t?.forks && t.forks?.number === 0 && t?.netWorth <= 0.001) continue;
          else actual.push(t);
        }

        setUserTallies(actual);
      }
    }

    fetchData();
    fetchRoundData();
    fetchLeaderboard();
    let storage = null;
    try {
      storage = localStorage.getItem('leaderboardTableState');
      storage = JSON.parse(storage);
      setLeaderboardState(storage.columns);
    } catch {}

    getAllRounds();

    let path = '';
    if (params) {
      path = window.location.href.slice(0, -round.toString().length - 1);
    } else {
      path = window.location.href;
    }
    if (path.charAt(path.length - 1) === '#') {
      // remove trailing slash
      path = path.slice(0, -1);
    }
    if (path.charAt(path.length - 1) === '/') {
      // remove trailing slash
      path = path.slice(0, -1);
    }
    setPathName(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  useEffect(() => {
    let tempTallies = [];
    if (userTallies) {
      // eslint-disable-next-line array-callback-return
      userTallies.map((tally, i) => {
        if (!tally.investmentsOwned) {
          tally.investmentsOwned = {};
        }
        const displayName = tally.player.username
          ? tally.player.username.length > 17
            ? tally.player.username.substr(0, 17) + '...'
            : tally.player.username
          : tally.player.address.substr(0, 15) + '...';
        const displayLink = createDisplayLink(displayName, tally.player.address);
        tempTallies.push({
          id: i + 1,
          rank: i + 1,
          user: [displayLink, '/player/' + tally.player.address],
          username: displayName,
          netWorth: Util.currencyFormat(tally.netWorth).toLocaleString(),
          netChange: Util.currencyFormat(
            tally.netWorth - (tally.leaderboard.previousNet ? tally.leaderboard.previousNet : tally.netWorth),
          ).toLocaleString(),
          cards: tally.activeCards ? Object.keys(tally.activeCards).length : 0,
          forkPCT: tally.forks ? Util.currencyFormat(Math.round(tally.forks.bonus * 100)).toLocaleString() + '%' : 0,
          forks: tally.forks ? tally.forks.number : 0,
          onBlock: tally.forks
            ? tally.forks.onBlocks[tally.forks.onBlocks.length - 1]
              ? tally.forks.onBlocks[tally.forks.onBlocks.length - 1]
              : '-'
            : '-',
          earning: tally.lastTally.earningsPerBlock
            ? Util.currencyFormat(tally.lastTally.earningsPerBlock).toLocaleString()
            : '0',
          staked: tally.lastTally.stakedValue ? Util.currencyFormat(tally.lastTally.stakedValue).toLocaleString() : '0',
          cash: Util.currencyFormat(tally.cashOnHand).toLocaleString(),
          // in some rounds the investmentsOwned are two dimensional arrays, then switched to be an object on round 24(?), so check

          contributions: tally.contributions
            ? Util.currencyFormat(Object.values(tally.contributions).reduce((a, b) => a + b, 0)).toLocaleString()
            : 0,
          rigs: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][0]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['0'] || 0
            : 0,
          nodes: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][1]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['1'] || 0
            : 0,
          oracles: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][2]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['2'] || 0
            : 0,
          contracts: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][3]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['3'] || 0
            : 0,
          bots: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][4]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['4'] || 0
            : 0,
          dex: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][5]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['5'] || 0
            : 0,
          cex: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][6]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['6'] || 0
            : 0,
          all: Array.isArray(tally.investmentsOwned)
            ? tally.investmentsOwned[0][0] +
              tally.investmentsOwned[0][1] +
              tally.investmentsOwned[0][2] +
              tally.investmentsOwned[0][3] +
              tally.investmentsOwned[0][4] +
              tally.investmentsOwned[0][5] +
              tally.investmentsOwned[0][6]
            : tally.investmentsOwned !== null
            ? tally.investmentsOwned['0'] +
              tally.investmentsOwned['1'] +
              tally.investmentsOwned['2'] +
              tally.investmentsOwned['3'] +
              tally.investmentsOwned['4'] +
              tally.investmentsOwned['5'] +
              tally.investmentsOwned['6']
            : 0,
        });
      });

      const legend = [];
      const netWorths = [];
      if (userTallies.length > 0) {
        // eslint-disable-next-line array-callback-return
        userTallies.map((tally, i) => {
          if (i < 50) {
            legend.push(i + 1);
            netWorths.push(tally.netWorth);
          }
        });
      }
      setChartData(netWorths);
      setChartLabels(legend);
    }
    setTallyData(tempTallies);
  }, [userTallies]);

  const options = {
    responsive: 'simple',
    selectableRows: 'none',
    filterType: 'checkbox',
    rowsPerPage: 50,
    rowsPerPageOptions: [25, 50, 100],
    print: false,
    download: false,
    columnOrder: null,
    fixedHeader: true,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    draggableColumns: { enabled: true },
    storageKey: 'leaderboardTableState',
    renderExpandableRow: (rowData, rowMeta) => {
      //const colSpan = rowData.length + 1;
      return (
        <tr>
          <td colSpan='8'>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <strong>Rigs</strong>
              </TableCell>
              <TableCell>
                <strong>VN</strong>
              </TableCell>
              <TableCell>
                <strong>OR</strong>
              </TableCell>
              <TableCell>
                <strong>SC</strong>
              </TableCell>
              <TableCell>
                <strong>ATB</strong>
              </TableCell>
              <TableCell>
                <strong>DEX</strong>
              </TableCell>
              <TableCell>
                <strong>CEX</strong>
              </TableCell>
              <TableCell>
                <strong>ALL</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell> {rowData[12]}</TableCell>
              <TableCell> {rowData[13]}</TableCell>
              <TableCell> {rowData[14]}</TableCell>
              <TableCell> {rowData[15]}</TableCell>
              <TableCell> {rowData[16]}</TableCell>
              <TableCell> {rowData[17]}</TableCell>
              <TableCell> {rowData[18]}</TableCell>
              <TableCell> {rowData[19]}</TableCell>
            </TableRow>
          </td>
        </tr>
      );
    },
  };

  const getTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: 'var(--gray) !important',
            color: '#fff !important',
          },
          paper: {
            boxShadow: 'none',
          },
        },
        MuiTableHead: {
          root: {
            backgroundColor: 'var(--light-gray) !important',
            fontWeight: 'bold',
            color: '#fff !important',
          },
        },
      },
    });

  return (
    <>
      {eventActive ? (
        <style jsx global>
          {eventBG}
        </style>
      ) : (
        <style jsx global>
          {mainBG}
        </style>
      )}

      <div style={{ top: '100px', position: 'absolute', width: '100vw' }}>
        <Helmet>
          <title>Mintopoly Explorer - Leaderboard</title>
        </Helmet>

        <Header round={round} paramSet={params.round ? true : false} />

        <div className='container-fluid mt-4 mb-5'>
          <div className='d-sm-flex align-items-center justify-content-between mb-4'>
            <h1 className='h3 mb-3 font-weight-bold text-white text-center'>
              Leaderboard – {Number(round) > 80 ? 'Campaign #' + (Number(round) - 80) : 'Round #' + Number(round)}
            </h1>

            <nav>
              <div className='collapse navbar-collapse' id='roundToggler'>
                <ul className='navbar-nav ml-auto'>
                  <li className='nav-item dropdown no-arrow'>
                    <a
                      href
                      className='nav-link dropdown-toggle'
                      id='userDropdown'
                      role='button'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                      alt=''
                    >
                      <i className='fas fa-caret-square-down fa-sm fa-fw mr-2 text-gray-400'></i>
                      <span className='mr-2 font-weight-bold d-none d-inline text-gray-600 small'>
                        {Number(round) > 80 ? 'Campaign ' + (Number(round) - 80) : 'Round ' + Number(round)}
                      </span>
                    </a>
                    <div className='dropdown-menu dropdown-menu-right shadow' aria-labelledby='userDropdown'>
                      {allRounds.map((r, i) => {
                        return r === Number(round) ? (
                          <a key={i} className='font-weight-bold dropdown-item' href={'/' + r}>
                            {r > 80 ? 'Campaign ' + (Number(r) - 80) : 'Round ' + r}
                          </a>
                        ) : (
                          <a key={i} className='dropdown-item' href={'/' + r}>
                            {r > 80 ? 'Campaign ' + (Number(r) - 80) : 'Round ' + r}
                          </a>
                        );
                      })}
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            <SearchForm />
          </div>

          <RoundCards gameData={gameData} tallies={userTallies} />

          <div className='row'>
            <div className='col-lg-12'>
              <div className='card shadow mb-4'>
                <div className='card-body card-leaderboard px-0 py-2 rounder-0'>
                  {tallyData.length === 0 ? (
                    <center>loading leaderboard...</center>
                  ) : (
                    <ThemeProvider theme={getTheme()}>
                      <MUIDataTable
                        className='leaderboardTable'
                        style={{
                          backgroundColor: 'var(--gray) !important',
                          color: '#fff !important',
                        }}
                        data={tallyData}
                        options={options}
                        columns={[
                          {
                            name: 'rank',
                            label: '#',
                            options: {
                              filter: false,
                              sort: true,
                              setCellProps: () => ({
                                style: { minWidth: '85x', maxWidth: '85px' },
                              }),
                              setCellHeaderProps: () => ({
                                style: { minWidth: '85px', maxWidth: '85px' },
                              }),
                            },
                          },
                          {
                            name: 'user',
                            label: 'User',
                            options: {
                              filter: false,
                              sort: false,
                              setCellProps: () => ({
                                style: { minWidth: '210px', maxWidth: '210px' },
                              }),
                              customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                  <a href={`${value[1]}`}>
                                    <strong>{value[0]}</strong>
                                  </a>
                                );
                              },
                            },
                          },
                          {
                            name: 'netWorth',
                            label: 'Net Worth',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'netWorth')
                                  ? leaderboardState.find((o) => o.name === 'netWorth').display
                                  : true,
                              filter: false,
                              sort: true,
                              searchable: true,
                              setCellProps: () => ({
                                style: { minWidth: '170px', maxWidth: '170px' },
                              }),
                            },
                          },
                          {
                            name: 'netChange',
                            label: 'Net Change',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'netChange')
                                  ? leaderboardState.find((o) => o.name === 'netChange').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: true,
                              setCellProps: () => ({
                                style: { minWidth: '170px', maxWidth: '170px' },
                              }),
                            },
                          },
                          {
                            name: 'contributions',
                            label: 'Contributions',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'contributions')
                                  ? leaderboardState.find((o) => o.name === 'contributions').display
                                  : true,
                              filter: false,
                              sort: true,
                              searchable: false,
                              sortCompare: (order) => {
                                return (obj1, obj2) => {
                                  let val1 = obj1.data ? obj1.data.replace(/[^\d.]/g, '') : 0;
                                  let val2 = obj2.data ? obj2.data.replace(/[^\d.]/g, '') : 0;
                                  val1 = parseFloat(val1, 8);
                                  val2 = parseFloat(val2, 8);
                                  return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                };
                              },
                              setCellProps: () => ({
                                style: { minWidth: '170px', maxWidth: '170px' },
                              }),
                            },
                          },

                          {
                            name: 'cash',
                            label: 'Cash',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'cash')
                                  ? leaderboardState.find((o) => o.name === 'cash').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                              sortCompare: (order) => {
                                return (obj1, obj2) => {
                                  let val1 = obj1.data.replace(/[^\d.]/g, '') || 0;
                                  let val2 = obj2.data.replace(/[^\d.]/g, '') || 0;
                                  val1 = parseFloat(val1, 8);
                                  val2 = parseFloat(val2, 8);
                                  return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                };
                              },
                            },
                          },

                          {
                            name: 'staked',
                            label: 'Staked',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'staked')
                                  ? leaderboardState.find((o) => o.name === 'staked').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                              sortCompare: (order) => {
                                return (obj1, obj2) => {
                                  let val1 = obj1.data.replace(/[^\d.]/g, '') || 0;
                                  let val2 = obj2.data.replace(/[^\d.]/g, '') || 0;
                                  val1 = parseFloat(val1, 8);
                                  val2 = parseFloat(val2, 8);
                                  return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                };
                              },
                            },
                          },

                          {
                            name: 'earning',
                            label: 'Earning',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'earning')
                                  ? leaderboardState.find((o) => o.name === 'earning').display
                                  : true,
                              filter: false,
                              sort: true,
                              searchable: false,
                              sortCompare: (order) => {
                                return (obj1, obj2) => {
                                  let val1 = obj1.data.replace(/[^\d.]/g, '') || 0;
                                  let val2 = obj2.data.replace(/[^\d.]/g, '') || 0;
                                  val1 = parseFloat(val1, 8);
                                  val2 = parseFloat(val2, 8);
                                  return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                };
                              },
                            },
                          },

                          {
                            name: 'cards',
                            label: 'Cards',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'cards')
                                  ? leaderboardState.find((o) => o.name === 'cards').display
                                  : true,
                              filter: true,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'forkPCT',
                            label: 'Fork %',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'forkPCT')
                                  ? leaderboardState.find((o) => o.name === 'forkPCT').display
                                  : true,
                              filter: false,
                              sort: true,
                              searchable: false,
                              setCellProps: () => ({
                                style: { minWidth: '140px', maxWidth: '140px' },
                              }),
                              sortCompare: (order) => {
                                return (obj1, obj2) => {
                                  if (obj1.data && obj2.data) {
                                    let val1 = obj1.data.replace(/[^\d.]/g, '') || 0;
                                    let val2 = obj2.data.replace(/[^\d.]/g, '') || 0;
                                    val1 = parseFloat(val1, 8);
                                    val2 = parseFloat(val2, 8);
                                    return (val1 - val2) * (order === 'asc' ? 1 : -1);
                                  }
                                };
                              },
                            },
                          },
                          {
                            name: 'forks',
                            label: 'Forks',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'forks')
                                  ? leaderboardState.find((o) => o.name === 'forks').display
                                  : true,
                              filter: true,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'onBlock',
                            label: 'on Block',
                            options: {
                              display: false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'rigs',
                            label: 'Rigs',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'rigs')
                                  ? leaderboardState.find((o) => o.name === 'rigs').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'nodes',
                            label: 'VN',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'nodes')
                                  ? leaderboardState.find((o) => o.name === 'nodes').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'oracles',
                            label: 'OR',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'oracles')
                                  ? leaderboardState.find((o) => o.name === 'oracles').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'contracts',
                            label: 'SC',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'contracts')
                                  ? leaderboardState.find((o) => o.name === 'contracts').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'bots',
                            label: 'Bots',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'bots')
                                  ? leaderboardState.find((o) => o.name === 'bots').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'dex',
                            label: 'DEX',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'dex')
                                  ? leaderboardState.find((o) => o.name === 'dex').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'cex',
                            label: 'CEX',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'cex')
                                  ? leaderboardState.find((o) => o.name === 'cex').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'all',
                            label: 'ALL',
                            options: {
                              display:
                                leaderboardState && leaderboardState.find((o) => o.name === 'all')
                                  ? leaderboardState.find((o) => o.name === 'all').display
                                  : false,
                              filter: false,
                              sort: true,
                              searchable: false,
                            },
                          },
                          {
                            name: 'username',
                            label: '',
                            options: {
                              display: true,
                              filter: false,
                              sort: true,
                              setCellProps: () => ({
                                style: { display: 'none' },
                              }),
                              setCellHeaderProps: () => ({
                                style: { display: 'none' },
                              }),
                            },
                          },
                        ]}
                      />
                    </ThemeProvider>
                  )}

                  {/* <div className="styled-table">
                <MaterialTable
                    columns={[
                        { title: "#", field: "rank", type: "numeric", filtering: false, cellStyle: {width: "50px!important",}},
                        { title: "", field: "view", searchable: false, filtering: false, cellStyle: {minWidth: "25px", maxWidth: "25px", fontWeight: "bold"} },
                        { title: "User", field: "user", searchable: true, filtering: false, sorting: false, cellStyle: {minWidth: "190px", maxWidth: "190px", fontWeight: "bold"} },
                        { title: "Net Worth", field: "netWorth", type: "numeric", filtering: false, sorting: true, cellStyle: {minWidth: "160px", maxWidth: "160px"} },
                        { title: "Cards", field: "cards", type: "numeric",
                            lookup: {0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10, 11: 11, 12: 12}
                            },
                        { title: "Fork%", field: "forkPCT", type: "numeric", filtering: false, cellStyle: {width: "90px"}},
                        { title: "Forks", field: "forks", type: "numeric", cellStyle: {width: "100px"} },
                        { title: "onBlock", field: "onBlock", filtering: false, sorting: false, cellStyle: {width: "130px"} },
                        { title: "Earning", field: "earning", type: "numeric", filtering: false, sorting: true, cellStyle: {minWidth: "155px", maxWidth: "155px"} },
                        { title: "Staked", field: "staked", type: "numeric", filtering: false, sorting: true, cellStyle: {minWidth: "155px", maxWidth: "155px"} },
                        { title: "Cash", field: "cash", type: "numeric", filtering: false, sorting: true, cellStyle: {minWidth: "155px", maxWidth: "155px"} },
                        { title: "Rigs", field: "rigs", type: "numeric", filtering: false, cellStyle: {width: "100px"} },
                        { title: "Nodes", field: "nodes", type: "numeric", filtering: false, cellStyle: {width: "100px"}     },
                        { title: "Oracles", field: "oracles", type: "numeric", filtering: false, cellStyle: {width: "100px"} },
                        { title: "Contracts", field: "contracts", type: "numeric", filtering: false, cellStyle: {width: "100px"} },
                        { title: "Bots", field: "bots", type: "numeric", filtering: false, cellStyle: {width: "100px"} },
                        { title: "DEX", field: "dex", type: "numeric", filtering: false, cellStyle: {width: "100px"} },
                        { title: "CEX", field: "cex", type: "numeric", filtering: false, cellStyle: {width: "100px"} },

                    ]}
                    data={tallyData}
                    options={{
                        pageSize: 25,
                        pageSizeOptions: [25, 50, 100],
                        filtering: true,
                        search: true,
                        showTitle: false,
                        tableLayout: "auto",
                        toolbar: true,
                        headerStyle: {
                            backgroundColor: "#3F85C8",
                            color: "#ffffff",
                            fontWeight: "bold",
                            padding: "12px 15px"
                        },
                        rowStyle: {
                            borderBottom: "1px solid #dddddd"
                        },
                    }}
                />
                </div>              */}
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* <div className="my-3"> */}
            {/* <center><div id="bsa-zone_1643833294544-4_123456"></div></center> */}
            {/* </div> */}
          </div>

          <div className='row'>
            {/* <div className='col-lg-6 mb-6'>
            <div className='card shadow mb-4'>
              <div className='card-header py-3'>
                <h6 className='m-0 font-weight-bold'>PRIZES</h6>
              </div>
              <div className='card-body'>
                <ul className='fa-ul mb-0 pb-0'>
                  {gameData ? (
                    gameData.rewards.map((reward, i) => {
                      return (
                        <li key={i}>
                          <span className='fa-li'>
                            <i className='fas fa-gift'></i>
                          </span>
                          {reward}
                        </li>
                      );
                    })
                  ) : (
                    <small>round prizes not found</small>
                  )}
                </ul>
              </div>
            </div>
          </div> */}

            <div className='col-lg-12 mb-5'>
              <div className='card shadow mb-4'>
                <div className='card-header py-3 d-flex flex-row align-items-center justify-content-between'>
                  <h6 className='m-0 font-weight-bold'>NET WORTH (Top 50)</h6>
                </div>
                <div className='card-body' style={{ maxHeight: '240px' }}>
                  <Bar
                    options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: { display: true },
                        title: {
                          display: false,
                        },
                      },
                    }}
                    data={{
                      labels: chartLabels,
                      datasets: [
                        {
                          label: 'Net Worth',
                          data: chartData,
                          backgroundColor: 'rgba(248, 214, 89, 1)',
                        },
                      ],
                    }}
                  />
                  <small>
                    <center>-player rank-</center>
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leaderboards;
